import React from 'react';
import "@fontsource/orbitron";

interface DateTimeDisplayProps {
  value: number;
  type: string;
  isDanger: boolean;
}

interface DoteCounterProps {
  dote: string;
}

const counterStyle: React.CSSProperties = {
  fontFamily: 'Orbitron, sans-serif',
  fontWeight: '700',
  color: '#1B1B3AB2',
  textAlign: 'center',
};

const counterTextStyle: React.CSSProperties = {
  fontFamily: 'Orbitron, sans-serif',
  fontWeight: '700',
  color: '#1B1B3A',
  textAlign: 'center',
  paddingBottom: '10px',
};

const counterDoubleDoteStyle: React.CSSProperties = {
  fontFamily: 'Orbitron, sans-serif',
  fontWeight: '700',
  color: '#1B1B3A',
  textAlign: 'center',
};

const styles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  alignItems: 'center',
};

const DateTimeDisplay: React.FC<DateTimeDisplayProps> = React.memo(({ value, type, isDanger }) => {
  return (
    <div style={styles} className={`countdown ${isDanger ? 'danger' : ''}`}>
        <div id="date_time_display" className="flex flex-col items-center justify-center">
          <div className=""><p style={{ ...counterStyle, fontSize: '8vw', textAlign: 'center' }}>{value}</p></div>
          <div><span className="h-full" style={{ ...counterTextStyle, fontSize: '4vw', maxWidth: '70px' }}>{type}</span></div>
        </div>
      </div>
    );
});

const DoteCounter: React.FC<DoteCounterProps> = React.memo(({ dote }) => {
  return (
    <div className="flex self-start">
      <p style={{ ...counterDoubleDoteStyle, fontSize: '8vw', maxWidth: '100px' }}>{dote}</p>
    </div>
  );
});

export { DateTimeDisplay, DoteCounter };
