import React, { useRef } from "react";

const Text: React.FC = () => {
    const headlineRef = useRef<HTMLDivElement>(null);
        return (
            <div className="text-justify">
                <div className="flex flex-col gap-y-3 pb-10">
                <p className={`w-full justify-start leading-8`}>
                    Пользовательское соглашение <br />
                    Российская Федерация, город Санкт-Петербург <br />
                    редакция от 25 августа 2023 года
                </p>
                <p>
                    Индивидуальный предприниматель «Кайтуков Ричард Хидир-Иласович», в
                    лице генерального директора Кайтукова Ричарда Хидир-Иласовича,
                    действующего на основании Устава, с одной стороны, и акцептовавшее
                    оферту, размещенную в приложении «Tuse», третье лицо заключили
                    настоящее пользовательское соглашение о нижеследующем:
                </p>
                </div>

                <div
                className={`flex flex-col justify-center items-start gap-y-4 pb-12`}
                >
                <div className="flex flex-col gap-y-2">
                    <p id="headline-1" ref={headlineRef} className="font-bold">1. Термины и определения</p>
                    <p>
                    1.1. В настоящем пользовательском соглашении, если из текста прямо
                    не вытекает иное, следующие термины будут иметь указанные ниже
                    значения:
                    </p>
                </div>

                <p>
                    «Заказ» - Должным образом оформленный с помощью Приложения запрос
                    Пользователя на участие в Мероприятии Исполнителя, свидетельствующий
                о намерении Пользователя заключить с Исполнителем в Приложении
                сделку об оказании услуг, устанавливающую обязанность Исполнителя
                провести Мероприятие и право Пользователя и (или) третьих лиц
                посетить Мероприятие в указанные в Электронном билете дату, время и
                место.
            </p>

            <p>
                «Исполнитель» - Физическое лицо, индивидуальный предприниматель или
                юридическое лицо, организующее Мероприятие и размещающее информацию
                о Мероприятии в Приложении «Tuse».
            </p>

            <p>
                «Рекомендации» - Информация, направляемая Компанией Пользователю
                любым способом, не противоречащим законодательству Российской
                Федерации, в том числе рекламная информация.
            </p>

            <p>
                «Компания» - Индивидуальный предприниматель «Кайтуков Ричард
                Хидир-Иласович» - юридическое лицо, зарегистрированное по
                законодательству Российской Федерации, ОГРНИП: 320151300020273 , ИНН
                151309349770
            </p>

            <p>
                «Мероприятие», «Событие» - Услуга, оказываемая Исполнителем
                Пользователю, информация о которой размещается Исполнителем в
                Приложении «Tuse», с целью, в том числе, сбора информации о
                Пользователях, заинтересованных в получении соответствующей услуги.
            </p>

            <p>
                «Пользователь» - Любое лицо, акцептовавшее оферту, размещенную в
                Приложении «Tuse».
            </p>

            <p>
                «Правила пользования Приложением «Tuse» - Обязательные к исполнению
                всеми Пользователями правила, постоянно размещенные в Приложении
                «Tuse».
            </p>

            <p>
                Приложение» - приложение «Tuse», являющееся ресурсом представляющим
                собой совокупность содержащихся в информационной системе информации
                и объектов интеллектуальной собственности (в том числе, программа
                для ЭВМ, база данных, графическое оформление интерфейса (дизайн) и
                др.), доступ к которому обеспечивается с различных пользовательских
                устройств, подключенных к сети Интернет. Приложения является
                Интернет - ресурсом, предназначенным для публикации и получения
                информации о различных Мероприятиях проводимых Исполнителем, а также
                для реализации Электронных билетов на Мероприятия, проводимые
                Исполнителями.
            </p>
            <p>
                «Соглашение» - Настоящее пользовательское соглашение, а также все
                приложения к нему.
            </p>

            <p>«Стороны» - Пользователь и Компания.</p>

            <p>
                «Счет» Расчетный счет Компании в кредитной организации, указанный в
                Приложении «Tuse», либо счет Компании у платежного агента, указанный
                в Приложении «Tuse».
            </p>

            <p>
                «Электронный билет» - Запись в системе учета Приложения, содержащая
                идентификационные данные Пользователя, Заказа и статус Заказа, и
                подтверждающая совершение сделки об оказании услуг, устанавливающей
                обязанность Исполнителя провести Мероприятие и право Пользователя и
                (или) третьих лиц посетить Мероприятие в указанные в Электронном
                билете дату, время и месте.
            </p>

            <p>
                Реализация/продажа/приобретение Электронных билетов означает
                совершение названных сделок. При этом Покупатель в рамках одной
                такой сделки может приобрести более одного Электронного билета.
            </p>

            <p>
                При реализации Электронного билета он предоставляется Пользователю в
                виде электронного документа, содержащего все существенные условия
                названной сделки.
            </p>

            <p>
                1.2. Все остальные термины и определения, встречающиеся в тексте
                Соглашения, толкуются Сторонами в соответствии с действующим
                законодательством Российской Федерации.
            </p>

            <p>
                1.3. Названия заголовков (статей) Соглашения предназначены
                исключительно для удобства пользования текстом Соглашения и
                буквального юридического значения не имеют.
            </p>
            </div>
            <div className="flex flex-col gap-y-4 items-start justify-center pb-12">
            <p id="headline-2" ref={headlineRef} className="font-bold">2. Предмет Соглашения</p>
            <p>
                2.1. В порядке и на условиях, предусмотренных Соглашением, Компания
                предоставляет Пользователю возможность использования Приложения, а
                Пользователь обязуется при необходимости использовать Приложение в
                соответствии с условиями Соглашения.
            </p>

            <p>
                2.2. На основании Соглашения Пользователь имеет возможность
                использовать Приложение, в частности путем получения размещенной в
                Приложении информации о Мероприятиях, а также путем получения
                возможности участвовать в Мероприятиях, оформив Заказ, осуществив
                действия, предусмотренные Исполнителем (в частности,
                зарегистрировавшись и при необходимости внеся соответствующую плату)
                и получив Электронный билет от Компании и чек, подтверждающий
                оплату. Кроме того, Пользователь вправе использовать Приложение иным
                способом, предусмотренным Компанией. В случаях, предусмотренных
                соответствующими соглашениями между Компанией и Исполнителем,
                Компания, действуя от своего имени, но за счет Исполнителя, либо
                действуя от имени и за счет Исполнителя, осуществляет реализацию
                Пользователям Электронных билетов.
            </p>

            <p>
                2.3. Если иное прямо не предусмотрено соглашением между Сторонами,
                Компания не является организатором Мероприятий, продавцом каких-либо
                товаров или услуг. Все обязательства по поводу проведения
                Мероприятий, купли-продажи соответствующих товаров или оказания
                соответствующих услуг возникают между Пользователем и Исполнителем
                и/или третьими лицами. Если иное прямо не предусмотрено соглашением
                между Сторонами, Компания не несет ответственность за
                действительность и исполнимость указанных обязательств. При этом,
                когда Пользователь приобретает Электронный билет путем перечисления
                денежных средств Компании, Компания принимает на себя обязательство
                выдать Пользователю Электронный билет путем его отправления на адрес
                электронной почты, указанный Пользователем при регистрации в
                Приложении.
            </p>

            <p>
                2.4. Заключая Соглашение, Пользователь соглашается с тем, что
                Компания вправе передать свои права и обязанности по Соглашению
                любым третьим лицам. Настоящий пункт является согласием Пользователя
                на перевод долга любому третьему лицу в соответствии с пунктом 1
                статьи 391 Гражданского кодекса Российской Федерации. Пользователь
                не вправе передать свои права по Соглашению третьим лицам без
                письменного согласия Компании.
            </p>
            </div>

            <div className="flex flex-col items-start justify-center gap-y-4 pb-12">
            <p id="headline-3" ref={headlineRef} className="font-bold">3. Права и обязанности Компании</p>
            <p>
                3.1. Компания вправе заблокировать доступ к Приложению для
                Пользователя, если он своими действиями или бездействием нарушает
                условия Соглашения, иные соглашения между Сторонами или иные
                требования Компании.
            </p>

            <p>
                3.2. Компания вправе без объяснения причин отказать Пользователю в
                оформлении Заказа. При этом, в случае если Пользователем был
                приобретен Электронный билет путем перечисления денежных средств
                Компании, Компания обязуется либо оформить Электронный билет, либо
                отказаться от его оформления.
            </p>

            <p>
                3.3. Компания вправе производить модификацию любого программного
                обеспечения Приложения, приостанавливать работу Приложения, при
                обнаружении существенных неисправностей, ошибок и сбоев, а также в
                целях проведения профилактических работ и предотвращения случаев
                несанкционированного доступа к Приложению.
            </p>

            <p>
                3.4. Компания вправе использовать данные Пользователя или иных лиц,
                информация о которых внесена Пользователем в Приложение, в
                маркетинговых целях. Пользователь гарантирует, что данные, внесенные
                Пользователем в Приложение, являются добровольно предоставленными им
                и третьими лицами, и третьи лица ознакомлены с условиями Соглашения.
            </p>

            <p>
                3.5. Компания вправе направлять Пользователю и иным лицам,
                информация о которых внесена Пользователем в Приложение,
                информационные и рекламные сообщения, как по адресу электронной
                почты, так и по номеру мобильного телефона, сведения о которых
                содержатся в Приложении. Настоящим Пользователь дает согласие на
                получение подобной информации, также гарантирует, что аналогичное
                согласие предоставлено и лицами, сведения о которых Пользователь
                внес в Приложение.
            </p>

            <p>
                3.6. Компания вправе произвести проверку данных Пользователя и иных
                лиц, указанных Пользователем, по своим критериям и критериям
                Исполнителей. Кроме того, Компания вправе требовать от Пользователя
                предъявления документов, удостоверяющих его личность, и
                предоставления иных данных, идентифицирующих Пользователя, в
                случаях, предусмотренных условиями соответствующих Мероприятий, либо
                законодательством Российской Федерации. В случае непредставления
                документов, либо несоответствия сведений в них, Компания вправе
                отказать Пользователю в использовании Приложения.
            </p>

            <p>
                3.7. Компания вправе консультировать Пользователя по ходу
                оформления/подтверждения/оплаты Заказа. В том числе самостоятельно
                связываться с Пользователем по указанным Пользователем номерам
                телефонов.
            </p>

            <p>
                3.8. Компания вправе выбирать самостоятельно и, по своему
                усмотрению, устанавливать способы идентификации Пользователя на всех
                своих ресурсах, в том числе сайтах, приложениях, серверах и прочих.
            </p>

            <p>
                3.9. Компания обязана информировать Пользователя о статусе Заказа и
                факте поступления оплаты за Заказ. Фактом того, что Пользователь
                информирован, является факт присваивания пользователю Электронного
                билета в соответствующем разделе приложения.{" "}
            </p>
            </div>
            <div className="flex flex-col items-start justify-center gap-y-4 pb-12">
            <p id="headline-4" ref={headlineRef} className="font-bold">4. Права и обязанности Пользователя</p>
            <p>
                4.1. Пользователь обязуется знакомиться с актуальной версией
                Соглашения при каждом посещении Приложения до момента пользования
                Приложением.
            </p>

            <p>
                4.2. Пользователь обязуется соблюдать Правила пользования
                Приложением.
            </p>

            <p>
                4.3. Пользователь обязуется предоставлять достоверную и по
                необходимости полную информацию при использовании Приложения.
            </p>

            <p>
                4.4. Пользователь обязуется самостоятельно проверить данные Заказа
                перед его оформлением. Пользователь несет полную ответственность за
                достоверность и правомерность употребления данных, использованных им
                при оформлении Заказа.
            </p>

            <p>
                4.5. Пользователь обязуется ознакомиться с содержанием, условиями
                регистрации и порядком проведения Мероприятия, а также с
                дополнительными требованиями, предъявляемыми Исполнителем до
                оформления Заказа. В случае если Пользователю не полностью ясны
                какие-либо условия проведения Мероприятия, в том числе, порядок
                оплаты, Пользователь обязуется уточнить эти условия, а при
                невозможности уточнения отказаться от оформления Заказа и посещения
                Мероприятия.
            </p>

            <p>
                4.6. Пользователь вправе выбирать из предложенных в Приложении
                способов способ оплаты участия в Мероприятии. При этом Пользователь
                обязуется самостоятельно ознакомиться с правилами пользования
                выбранным им способом платежа.
            </p>

            <p>
                4.7. Пользователь вправе до начала Мероприятия отказаться от участия
                в Мероприятии после оформления и оплаты Заказа на условиях
                Исполнителя. В этом случае Пользователь должен, обратится к
                Исполнителю, для согласования с ним условий о возврате денежных
                средств.
            </p>

            <p>
                4.8. Пользователь вправе оформить запрос на возврат 100% денежных
                средств, уплаченных за приобретенные им в рамках одного Заказа
                Электронные билеты, в случае отмены или переноса Мероприятия. В этом
                случае Пользователь должен обратиться с соответствующим заявлением к
                Исполнителю.
            </p>

            <p>
                4.9. Компания ни при каких обстоятельствах не несет ответственности
                за исполнение или неисполнение Исполнителем обязанности по возврату
                денежных средств Пользователю в случае отказа Пользователя от
                участия в Мероприятии, либо отмены или переноса Мероприятия
                Исполнителем. Все претензии, связанные с указанными действиями и
                событиями, Пользователь должен направлять непосредственно по
                указанным в Приложении реквизитам Исполнителя, либо по реквизитам
                Исполнителя, взятым из открытых реестров. В случае невозможности для
                Пользователя установить реквизиты Исполнителя, Компания по запросу
                пользователя предоставляет такие реквизиты Пользователю посредством
                Личного кабинета или по адресу электронной почты Пользователя.
            </p>

            <p>
                4.10. Пользователь обязуется оплатить в полном объеме самостоятельно
                или через третьих лиц стоимость Заказа. После оплаты Заказа
                Электронный билет считается проданным и у Пользователя возникает
                право посетить Мероприятие или воспользоваться иной услугой
                Исполнителя.
            </p>
            </div>
            <div className="flex flex-col items-start justify-center gap-y-4 pb-12">
            <p id="headline-5" ref={headlineRef} className="font-bold">
                5. Порядок оформления Заказа и получения Электронного билета:
            </p>
            <p>
                5.1. Пользователь, желающий посетить Мероприятие или воспользоваться
                иной услугой Исполнителя, информация о которой размещена в
                Приложении, изучает информацию о Мероприятии или услуге на
                соответствующей странице Приложения.
            </p>

            <p>
                5.2. Для оформления Заказа Пользователь должен совершить следующие
                действия:
            </p>

            <p>5.2.1. выбрать Мероприятие в Приложении «Tuse»;</p>

            <p>
                5.2.2. предоставить предусмотренные на странице Мероприятия сведения
                (предоставляемые сведения должны соответствовать действительности и
                предоставляться в необходимом объеме)
            </p>

            <p>
                5.2.3. подтвердить корректность внесенных сведений и желание
                оформить заказ нажатием кнопки «Продолжить», либо
                «Зарегистрироваться» на соответствующей странице Приложения;
            </p>

            <p>
                5.3. Компания подтверждает принятие и оформление Заказа путем
                отправки Электронного билета в соответствующий раздел Приложения.{" "}
            </p>
            <p>
                5.4. В случае если участие в Мероприятии является для Пользователя
                безвозмездным, а Исполнителем не установлено дополнительных
                требований для участия в Мероприятии, вместе с подтверждением
                оформления Заказа Компания направляет Пользователю сообщение с
                уведомлением о возможности участия в Мероприятии.
            </p>

            <p>
                5.5. Если для участия в Мероприятии Пользователь должен оплатить
                Заказ, после подтверждения Заказа Приложение перенаправляет
                Пользователя на страницу оплаты Заказа. Для оплаты Заказа
                Пользователь выбирает соответствующий способ оплаты/знакомится с
                предложенным способом оплаты, указанный на странице Приложения и
                производит оплату в соответствии с правилами осуществления платежей
                выбранного Пользователем способа оплаты. После оплаты Заказа
                Компания направляет Пользователю сообщение с уведомлением о
                получении оплаты и о возможности участия в Мероприятии, а также
                формирует Электронный билет.
            </p>

            <p>
                5.6. Моментом оплаты Заказа является момент поступления денежных
                средств на Счет. С момента оплаты Заказа между Исполнителем и
                Пользователем возникают предусмотренные условиями Мероприятия
                обязательства. С того же момента у Компании возникает обязательство
                по выдаче Пользователю Электронного билета.
            </p>

            <p>
                5.7. В случае внесения за Пользователя платежа третьими лицами все
                права и обязанности в отношении этого платежа возникают у
                Пользователя, и такие действия третьих лиц оцениваются Сторонами как
                совершенные указанными третьими лицами в интересах Пользователя и не
                порождающие каких-либо прав и обязанностей между указанными третьими
                лицами и Компанией.
            </p>

            <p>
                5.8. Пользователь участвует в Мероприятии в соответствии с
                правилами, установленными Исполнителем.
            </p>
            </div>

            <div className="flex flex-col items-start justify-center gap-y-4 pb-12">
            <p id="headline-6" ref={headlineRef} className="font-bold">6. Ответственность</p>
            <p>
                6.1. Информация, размещаемая в Приложении, добавляется в Приложение
                Исполнителями. Компания не имеет технической возможности и не
                обязана контролировать размещенную информацию и подвергать ее
                обязательной проверке и одобрению. Компания не гарантирует
                достоверность, точность, полноту или качество любой информации,
                публикуемой Исполнителями и/или третьими лицами в Приложении.
                Компания не поддерживает и не подтверждает никакую информацию,
                размещенную Исполнителями и/или третьими лицами в Приложении.
                Пользователь понимает и соглашается, что, используя Приложение, он
                может увидеть информацию, которая является оскорбительной,
                недостойной или спорной.
            </p>

            <p>
                6.2. Компания не имеет возможности производить проверку размещаемой
                Исполнителями и не может гарантировать полное отсутствие неточностей
                в ней, в связи с чем не несет перед Пользователем ответственности за
                любые ошибочные и/или недостоверные данные о Мероприятиях и услугах,
                равно как и за причиненный Пользователю вред и/или убытки из-за
                наличия ошибок в информации.
            </p>

            <p>
                6.3. Любые материалы, полученные с использованием Приложением,
                Пользователь может использовать на свой собственный страх и риск, на
                него возлагается ответственность за любой ущерб, который может быть
                нанесен в результате использования этих материалов. Компания ни при
                каких обстоятельствах не несет ответственности перед Пользователем
                или третьими лицами за любой ущерб, вызванный использованием
                Приложением, содержимого Приложения или иных материалов, к которым
                был получен доступ с Приложения.
            </p>

            <p>
                6.4. Компания не несет ответственности за некорректное поведение
                лиц, использующих Приложение.
            </p>

            <p>6.5. Компания не гарантирует, что:</p>

            <p>
                6.5.1. Приложение «Tuse» будет соответствовать требованиям
                Пользователя;
            </p>

            <p>
                6.5.2. результаты, которые могут быть получены с использованием
                Приложения, будут точными и надежными;
            </p>

            <p>
                6.5.3. качество какого-либо продукта, услуги, информации,
                полученного с использованием Приложения, будет соответствовать
                ожиданиям Пользователя;
            </p>

            <p>
                6.5.4. Приложение будет работать непрерывно, быстро, надежно и без
                ошибок и будет соответствовать ожиданиям Пользователя;
            </p>

            <p>6.5.5. все ошибки в Приложении будут исправлены.</p>

            <p>
                6.6. Приложение или его элементы периодически могут быть частично
                или полностью недоступны по причине проведения профилактических или
                других работ технического характера, обеспечивающих нормальное
                функционирование Приложения. В таком случае Компания не несет
                обязательств по уведомлению Пользователей или неполучению ими
                информации.
            </p>

            <p>
                6.7. Размер убытков, которые могут быть возмещены Компанией
                Пользователю и/или любому третьему лицу, в любом случае ограничен в
                соответствии с положениями части 1 статьи 15 Гражданского кодекса
                Российской Федерации суммой в 1 000 (одна тысяча) рублей.
            </p>

            <p>
                6.8. Компания не несет ответственности за несоблюдение Исполнителем
                условий проведения Мероприятий или предоставления услуг, так как
                данные условия и правила находятся в исключительном ведении
                Исполнителя.
            </p>

            <p>
                6.9. Пользователь принимает на себя полную ответственность и риски,
                связанные с оформлением Заказов. Пользователь несет единоличную
                ответственность за любую проблему, возникшую в процессе регистрации,
                оформления, подтверждения и оплаты Заказа. В подобном случае
                Компания не несет ответственность за любой ущерб (денежного или
                иного характера), возникший в результате проблемы, вызванной или
                обусловленной ошибкой или халатностью Пользователя при использовании
                Приложения, в том числе, неуспешным завершением или подтверждением
                любой операции.
            </p>

            <p>
                6.10. В случае невозможности Пользователем воспользоваться
                оплаченными в полном объеме услугами, по причинам, возникшим по вине
                Компании Пользователь вправе обратиться в службу поддержки Компании
                по адресу электронной почты support@tuse.app для решения возникших
                технических проблем доступа. При невозможности решения возникшей
                проблемы доступа, Пользователь вправе требовать возврата денег за не
                оказанную услугу. Компания обязуется осуществить возврат денежных
                средств на банковский счёт Пользователя в течение 30 (тридцати)
                дней.
            </p>

            <p>
                6.10. В случае осуществления Пользователем возврата денежных средств
                оплаченных с целью получения услуг посещения Мероприятия
                Пользователь обязан обратиться к Исполнителю, используя контактные
                данные указанные в Электронном билете. Исполнитель обязуется
                осуществить возврат денежных средств Пользователю в течение 30
                (тридцати) дней.
            </p>
            </div>
            <div className="flex flex-col gap-y-4 items-start justify-center pb-12">
            <p id="headline-7" ref={headlineRef} className="font-bold">7. Персональные данные</p>
            <p>
                7.1. Внося при регистрации на Мероприятие и оформлении Заказа свои
                персональные данные в форму в Приложении, либо в форму Компании,
                расположенную на ином сайте в сети Интернет/в ином Приложении,
                Пользователь дает свое согласие на обработку персональных данных,
                как без использования средств автоматизации, так и с их
                использованием. Указанное согласие предоставляется пользователем
                Компании, а также Исполнителю.
            </p>

            <p>
                7.2. Настоящим Пользователь подтверждает, что он передает свои
                персональные данные для обработки Компании и соответствующему
                Исполнителю и согласен на их обработку. Пользователь также
                уведомлен, что обработка его персональных данных будет
                осуществляться Компанией и Исполнителем на основании пункта 5 части
                1 статьи 6 Федерального закона №152-ФЗ от 27 июля 2006 года «О
                персональных данных» (в редакции от 25.07.2011), а также на
                основании пункта 1 части 1 статьи 6 Федерального закона №152-ФЗ от
                27 июля 2006 года «О персональных данных» (в редакции от 25.07.2011)
                в связи с чем Пользователь при необходимости выдает согласие на
                обработку персональных данных.
            </p>

            <p>
                7.3. Пользователь дает Компании и Исполнителю согласие на обработку
                следующих персональных данных:{" "}
            </p>
            <p>7.3.1. номера контактного телефона;</p>
            <p>7.3.2. адреса электронной почты;</p>
            <p>7.3.3. сведения об интересах;</p>
            <p>7.3.4. имени;</p>
            <p>7.3.5. возраста;</p>
            <p>7.3.6. пола.</p>

            <p>
                7.4. Целями обработки персональных данных является исполнение
                Компанией и Исполнителем соответствующих договоров, а также
                проведение необходимых маркетинговых мероприятий, в том числе, но не
                ограничиваясь, осуществление информационной рассылки (о
                Мероприятиях, акциях и др.) на адрес электронной почты.
            </p>

            <p>
                7.5. В ходе обработки с персональными данными будут совершены
                следующие действия: сбор, запись, систематизация, накопление,
                хранение, уточнение (обновление, изменение), извлечение,
                использование, передача (распространение, предоставление, доступ),
                обезличивание, блокирование, удаление, уничтожение.
            </p>

            <p>
                7.6. Передача персональных данных третьим лицам осуществляется в
                соответствии с действующим законодательством Российской Федерации и
                соглашениями между Сторонами.
            </p>

            <p>
                7.7. Регистрируясь на Мероприятие, размещенное в Приложении,
                Пользователь соглашается, что его персональные данные становятся
                доступны Исполнителям, являющимся организаторами (администраторами)
                данного Мероприятия.
            </p>

            <p>
                7.8. Персональные данные обрабатываются до момента ликвидации
                Компании, либо до ликвидации Приложения (в зависимости от того,
                какое событие наступит позднее). Персональные данные обрабатываются
                Исполнителем в порядке, предусмотренном Исполнителем. Хранение
                персональных данных осуществляется согласно действующему
                законодательству Российской Федерации.
            </p>

            <p>
                7.9. Согласие на обработку персональных данных Компанией может быть
                отозвано Пользователем, путем направления письменного заявления
                представителю Компании на электронный адрес support@tuse.app.
            </p>

            <p>
                7.10. В случае отзыва Пользователем согласия на обработку
                персональных данных Компания вправе продолжить обработку
                персональных данных без согласия Пользователя при наличии оснований,
                указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и
                части 2 статьи 11 Федерального закона №152-ФЗ от 27 июля 2006 года
                «О персональных данных» (в редакции от 25.07.2011).
            </p>

            <p>
                7.11. Политика по обработке персональных данных Компании является в
                соответствии с требованиями части 2 статьи 18.1 Федерального закона
                №152-ФЗ от 27 июля 2006 года «О персональных данных» (в редакции от
                25.07.2011) общедоступной и постоянно расположена в Приложении
                (ссылка на политику обработки персональный данных.)
            </p>
            </div>
            <div className="flex flex-col items-start justify-center gap-y-4 pb-12">
            <p id="headline-8" ref={headlineRef} className="font-bold">8. Рекомендации</p>
            <p>
                8.1. Компания вправе направлять Пользователю, выразившему свое
                согласие, Рекомендации, а именно рекламу и иную информацию о
                Мероприятиях.
            </p>

            <p>
                8.2. Пользователь выражает свое согласие на получение Информационных
                материалов при регистрации на Мероприятие.
            </p>

            <p>
                8.3. Если Пользователь не желает получать Рекомендации и не дает
                своего согласия, то он обязан сообщить об этом, направив уведомление
                Компании.
            </p>
            </div>
            <div className="flex flex-col items-start justify-center gap-y-4 pb-12">
            <p id="headline-9" ref={headlineRef} className="font-bold">9. Разрешение споров и применимое право</p>
            <p>
                9.1. Соглашение и все возникающие из него правоотношения
                регулируются законодательством Российской Федерации. Все возникающие
                споры разрешаются на основании законодательства Российской
                Федерации.
            </p>

            <p>
                9.2. Все споры, разногласия и претензии, которые могут возникнуть в
                связи с исполнением, расторжением или признанием недействительным
                Соглашения, Стороны будут стремиться решить путем переговоров.
                Сторона, у которой возникли претензии и/или разногласия, направляет
                другой Стороне сообщение с указанием возникших претензий и/или
                разногласий.
            </p>

            <p>
                9.3. Указанное в пункте Уведомление направляется Пользователем по
                электронной почте на адрес support@tuse.app, а также направляется
                Компании в письменном виде посредством отправки по почте заказным
                письмом с уведомлением о вручении.{" "}
            </p>

            <p>
                9.4. В течение 17 (семнадцати) календарных дней с момента получения
                указанного в пункте 9.2 Соглашения сообщения, при условии, что
                данное сообщение соответствует положениям пункта 9.3 Соглашения,
                Сторона, получившая его, обязана направить ответ на это сообщение.
            </p>

            <p>
                9.5. В случае если ответ на сообщение не будет получен направившей
                сообщение Стороной в течение 25 (двадцати пяти) календарных дней с
                даты направления соответствующего сообщения, либо если Стороны не
                придут к соглашению по возникшим претензиям и/или разногласиям, спор
                подлежит передаче на рассмотрение в суд общей юрисдикции по месту
                нахождения Компании.
            </p>
            </div>
            <div className="flex flex-col items-start justify-center gap-y-4">
            <p id="headline-10" ref={headlineRef} className="font-bold">
                10. Вступление Соглашения в силу и изменение условий Соглашения
            </p>
            <p>
                10.1. Текст Соглашения, постоянно размещенный в Приложении «Tuse»,
                содержит все существенные условия Соглашения и является предложением
                Компании заключить Соглашение с Пользователем, на указанных в тексте
                Соглашения условиях. Таким образом, в соответствии с положениями
                пункта 2 статьи 437 Гражданского кодекса Российской Федерации, текст
                Соглашения является публичной офертой.
            </p>

            <p>
                10.2. В соответствии с пунктом 3 статьи 438 Гражданского кодекса
                Российской Федерации, надлежащим акцептом этой оферты считается
                последовательное осуществление любым третьим лицом следующих
                действий:
            </p>

            <p>10.2.1. ознакомление с условиями Соглашения;</p>

            <p>
                10.2.2. введение регистрационных данных в профиле при регистрации и
                подтверждение достоверности этих данных нажатием кнопки
                «Продолжить», либо «Зарегистрироваться».
            </p>

            <p>
                10.3. С момента нажатия кнопки «Продолжить», либо
                «Зарегистрироваться» соответственно и в случае верного
                последовательного выполнения всех указанных выше действий,
                Соглашение считается заключенным.
            </p>

            <p>
                10.4. Соглашение может быть расторгнуто в любое время по инициативе
                каждой Стороны. Для этого Компания направляет Пользователю
                соответствующее уведомление на адрес электронной почты, с момента
                направления такого уведомления Соглашение считается расторгнутым.
                Пользователь может расторгнуть, направив Компании по электронной
                почте на адрес support@tuse.app сообщение о расторжении Соглашения.
            </p>

            <p>
                10.5. Стороны соглашаются, что Соглашение может быть изменено
                Компанией в одностороннем порядке путем размещения обновленного
                текста Соглашения Приложении «Tuse». Пользователь подтверждает свое
                согласие с изменениями условий Соглашения путем использования
                Приложения. При несогласии с измененной версией Соглашения,
                Пользователь прекращает пользование Приложения.
            </p>

            <p>
                10.6. Пользователь, не согласный с условиями Соглашения и/или с
                изменением условий Соглашения должен незамедлительно расторгнуть
                Соглашение в порядке, предусмотренном пунктом 10.4 Соглашения.
            </p>
            </div>
        </div>
    )
}
export default Text;