import React from "react";
import { DateTimeDisplay, DoteCounter } from "./DateTimeDisplay";
import useCountdown from "./../hooks/useCountdown";

interface CountdownTimerProps {
  targetDate: Date | number | null;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ targetDate }) => {
  const countDown = useCountdown(typeof targetDate === 'number' ? targetDate : targetDate instanceof Date ? targetDate.getTime() : null);
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  const headerStyle: React.CSSProperties = {
    fontFamily: 'Orbitron, sans-serif',
    fontWeight: '700',
    color: '#1B1B3A',
    textAlign: 'center',
    fontSize: '4vw', 
    whiteSpace: 'nowrap', 
    textOverflow: 'ellipsis',
  };

  return (
    <div id='div_counter' className="flex flex-col flex-1 bg-backGround h-auto my-5 mx-5 justify-center items-center rounded-[24px] p-5">
      <p style={headerStyle}>До нового мероприятия</p>
      <div className="flex w-full flex-row justify-center items-center show-counter gap-2 md:gap-4">
        <DateTimeDisplay value={days} type={'Дней'} isDanger={days <= 3} />
          <DoteCounter dote={":"} />
        <DateTimeDisplay value={hours} type={'Часов'} isDanger={false} />
          <DoteCounter dote={":"} />
        <DateTimeDisplay value={minutes} type={'Минут'} isDanger={false} />
          <DoteCounter dote={":"} />
        <DateTimeDisplay value={seconds} type={'Секунд'} isDanger={false} />
      </div>
    </div>
  );
};

export default React.memo(CountdownTimer);
