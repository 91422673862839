import React from 'react';
import './../src/output.min.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';


const container = document.getElementById('app');
const root = createRoot(container!)
root.render(<App/>);
reportWebVitals();