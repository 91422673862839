import React, { FC } from 'react';

import aboutSection_people from './../../assets/aboutSection_people.svg';
import GooglePlayCompo from './market_compo/GooglePlayCompo';
import AppStoreCompo from './market_compo/AppStoreCompo';
import { handleDownloadClick } from '../../App';

const AboutSection: FC = () => {
    return (
        <section id="about_section" className="lg:pl-[12px] relative pb-8 sm:pb-2 bg-gradient-to-t from-[#E8EEF2] from-50% to-[#FFFFFF] top-50%">
            <div id="welcome" className="flex flex-row h-full items-center xl:flex-row pl-[33px] md:pl-0 md:justify-center 1md:pr-0 lg:pr-12">
                <div className='flex flex-col w-2/3 xl:w-full'>
                    <div className="flex flex-col sm:h-full md:justify-center md:px-[5px] gap-y-10 sm:gap-4">
                        <p id="upper_main_text" className="text-[45px] pl-10 self-start 1lg:self-center sm:pl-0 break-keep leading-[70px] sm:leading-[50px] text-center 2md:text-[40px] font-extrabold text-text">Добро пожаловать в Tuse - ваш <br /> город в вашем кармане!</p>
                        <h2
                            id="bottom_main_text"
                            className="2md:w-full w-2/3 self-start text-[30px] text-text 2md:text-[25px] text-justify tracking-tight sm:text-[20px] pr-16 lg:pr-6"
                        >
                            Tuse открывает перед вами целую "тусячу" возможностей. От вечеринок и мастер-классов до
                            культурных событий и локальных ярмарок.
                        </h2>
                    </div>
                    <div id='div_text_app_google_div' className="flex justify-center w-2/3 xl:w-1/2 1md:pr-0 mt-[100px] 2md:mt-[40px] 2md:h-full 2md:items-center sm:pr-2 ">
                        <div id="div_text_app_google" className='flex flex-col items-center justify-between xl:justify-normal'>
                            <p id="text-1" className="text-[30px] 1md:text-[22px] 2md:text-center font-bold text-text">Узнай свой город заново.</p>
                            <div id="load_icons" className="flex flex-row 2md:flex-col 2md:gap-y-4 1lg:gap-x-5 gap-x-24 pt-6">
                                <AppStoreCompo onClick={() => handleDownloadClick()} />
                                <GooglePlayCompo onClick={() => handleDownloadClick()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img id='tuse_people_div'
                className='tuse_people absolute flex bottom-0 justify-end right-0 w-7/12 xl:w-1/2'
                alt='tuse_people'
                src={aboutSection_people}
            />
        </section>
    );
};

export default AboutSection;
