import React, { Component } from "react";
import Modal from '../components/modal/Modal';
import AboutSection from "../components/aboutSection/AboutSection";
import MainContent from "../components/main/MainContent";
import CountdownTimer from "../components/partyTime/CountdownTimer";

interface PageState {
  showPrivacyPolicy: boolean;
  showModal: boolean;
  scrollY: number;
  lastScroll: number;
  scrollPosition: () => number;
  containHide: () => boolean;
  defaultOffset: number;
  targetDate: null | Date ;
}

export default class Main extends Component<{}, PageState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      scrollY: 0,
      showModal: false,
      showPrivacyPolicy: false,
      lastScroll: 0,
      scrollPosition: this.getScrollPosition,
      containHide: this.containHide,
      defaultOffset: 0,
      targetDate: null,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  getScrollPosition = (): number => {
    return window.scrollY;
  };

  scrollPosition = (): number => {
    return window.scrollY;
  };

  containHide = (): boolean => {
    return false;
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  defaultOffset = 0;

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleSectionAnimations();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const header = document.querySelector('.header');

    if (header) {
      const { scrollPosition, lastScroll, containHide, defaultOffset } = this.state;

      if (scrollPosition() > lastScroll && !containHide() && scrollPosition() > defaultOffset) {
        header.classList.add('hide');
      } else if (scrollPosition() < lastScroll || !containHide()) {
        header.classList.remove('hide');
      }
      this.setState({ lastScroll: scrollPosition() });
    }

    const { scrollY } = window;
    this.setState({ scrollY });
    this.handleSectionAnimations();
    this.setState({
      scrollY: window.scrollY,
    });
    if (scrollY > this.state.scrollY) {
      this.handleSectionAnimations();
    }
  };

  handleSectionAnimations = () => {
    const aboutSection = document.getElementById("about_section");
    const aboutSectionBottom = aboutSection ? aboutSection.offsetTop + aboutSection.offsetHeight / 5 : 0;

    const listItems = document.querySelectorAll("#subSection-1, #subSection-2, #subSection-3, #subSection-4, #subSection-5, #subSection-6, #subSection-7");

    for (let i = 0; i < listItems.length; i++) {
      const listItem = listItems[i] as HTMLElement;

      if (this.isPartiallyVisible(listItem) && window.scrollY > aboutSectionBottom) {
        listItem.classList.add("active");
      }
    }
  };

  isFullyHidden = (el: Element) => {
    const elementBoundary = el.getBoundingClientRect();
    const bottom = elementBoundary.bottom;

    return bottom <= 0;
  };

  isPartiallyVisible = (el: Element) => {
    const elementBoundary = el.getBoundingClientRect();
    const top = elementBoundary.top;
    const bottom = elementBoundary.bottom;
    const height = elementBoundary.height;

    return (top + height >= 0) && (height + window.innerHeight >= bottom);
  };

  isFullyVisible = (el: Element) => {
    const elementBoundary = el.getBoundingClientRect();
    const top = elementBoundary.top;
    const bottom = elementBoundary.bottom;

    return (top >= 0) && (bottom <= window.innerHeight);
  };

  getPlatform = () => {
    const userAgent = window.navigator.userAgent;

    if (/Mobi|Android/i.test(userAgent)) {
      return 'android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'ios';
    }
    if (/Mac|iMac|MacBook/.test(userAgent)) {
      return 'macos';
    }
    return 'desktop';
  };

  handleDownloadClick = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      window.location.href = this.android_url;
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.location.href = this.ios_url;
    } else if (/Mac|iMac|MacBook/.test(userAgent)) {
      window.location.href = this.ios_url;
    } else {
      window.location.href = this.android_url;
    }
  };

  android_url = "https://play.google.com/store/apps/details?id=com.deadfonki.tuse";
  ios_url = "https://apps.apple.com/ru/app/id1532516520/id1532516520";

  
  render() {
    return (
      <div className="body">
        <AboutSection />
        <CountdownTimer targetDate={this.state.targetDate} />
        <MainContent handleDownloadClick={this.handleDownloadClick} />
        {this.state.showModal && <Modal closeModal={this.closeModal} />}
      </div>
    );
  }
}
