import React, { Component } from 'react';
import map from '../../assets/map.svg';
import data from '../../assets/data.svg';
import chat from '../../assets/chat.svg';
import party_people from '../../assets/party_people.svg';
import tickets from '../../assets/tickets.svg';
import payment from '../../assets/payment.svg';
import richard1 from '../../assets/richard1.jpg'
import farkhad from '../../assets/farkhad.jpg'
import anna from '../../assets/anna.jpg'
import tigran from '../../assets/tigran.jpg'

interface MainContentProps {
  handleDownloadClick: () => void;
}

export default class MainContent extends Component<MainContentProps> {
render() {
    return (
        <main className="flex flex-col w-screen px-[50px] sm:px-[10px] parallax-layer" id="mainContent">
            <section className="flex flex-col sm:pt-2 justify-center parallax-layer section">
                <p id="headline-1" className="flex parallax-text text-[45px] 2md:text-[35px] sm:text-[25px] justify-center font-bold">Все под рукой</p>
                <div id="subSection-1" className="flex flex-row pt-[20px] items-center justify-center subSection">
                    <div className="flex justify-center">
                        <img className="shrink w-4/5" src={map} alt="map"/>
                    </div>
                    <div id="box_container" className="flex ml-10 text-[35px] w-[850px] 1md:h-[80px] 1md:rounded-[30px]
                    lg:text-[30px] justify-center bg-btnPreView rounded-[48px] text-white items-center">
                        <p id="text" className="font-bold shrink my-[15px] text-center 1md:leading-tight">Найди идеальное <br/> мероприятие для себя</p>
                    </div>
                </div>
                <div id="subSection-2" className="flex flex-row items-center justify-center pt-5">
                    <div id="box_container" className="flex mr-10 sm:mr-3 text-white text-[35px] lg:text-[30px] 1md:h-[80px] 1md:rounded-[30px]
                        sm:text-[20px] justify-center items-center w-[750px] h-[138px] bg-btnLoad rounded-[48px]">
                        <p id="text" className="font-bold shrink text-center 1md:leading-tight">или создай свое и <br/> пригласи друзей.</p>
                    </div>
                    <div className="flex justify-center">
                        <img className="shrink w-4/5" src={data} alt="data"/>
                    </div>
                </div>
            </section>

            <section className="flex flex-col justify-center sm:pt-3 pt-36 parallax-layer">
                <p id="headline-2" className="flex justify-center text-[45px] 2md:text-[35px] sm:text-[25px] font-bold">Сообщество</p>
                <div id="subSection-3" className="flex flex-row lg:text-3xl pt-[10px] items-center justify-center font-bold parallax_inner">
                    <div className="flex justify-center">
                        <img className="shrink w-4/5" src={chat} alt="chat"/>
                    </div>
                    <div className="flex w-[850px] ml-10 text-[40px] text-justify lg:text-[30px] sm:text-[20px] justify-end">
                        <p id="text" className="font-bold 1md:leading-tight shrink flex-initial text-center">Общайтесь и делитесь впечатлениями,</p>
                    </div>
                </div>
                <div id="subSection-4" className="flex flex-row font-bold items-center justify-center text-center">
                    <div className="flex items-center w-[750px] h-[138px] text-[40px] lg:text-[30px] sm:text-[20px] justify-center">
                        <p id="text" className="font-bold 1md:leading-tight shrink">найдите единомышленников и <br className="xl:hidden"/> организуйте незабываемые встречи с Tuse.</p>
                    </div>
                    <div className="flex justify-center">
                        <img className="shrink w-4/5" src={party_people} alt="red_mark"/>
                    </div>
                </div>
            </section>

            <section className="flex flex-col justify-center sm:pt-3 pt-36 parallax-layer">
            <p id="headline-3" className="flex text-[45px] 2md:text-[35px] sm:text-[25px] justify-center font-bold">Простота и удобство</p>
                <div id="subSection-5" className="flex flex-row pt-[20px] items-center justify-center parallax-layer.drinks'">
                    <div className="flex justify-center">
                        <img className="shrink w-4/5" src={payment} alt="drinks"/>
                    </div>
                    <div id="box_container" className="flex ml-10 sm:ml-2 text-[40px] w-[850px] h-[138px] 1md:h-[80px]
                    lg:text-[30px] sm:text-[20px] justify-center bg-btnLoad rounded-[48px] text-white items-center">
                        <p id="text" className="font-bold shrink text-center 1md:px-2 1md:leading-tight">Плати любым удобным для тебя способом</p>
                    </div>
                </div>
                <div id="subSection-6" className="flex flex-row items-center justify-center pt-5">
                    <div id="box_container" className="flex mr-10 sm:mr-0 text-[40px] h-[138px] 1md:h-[80px] lg:text-[30px] sm:text-[20px]
                        justify-center items-center w-[900px] bg-[#E8EEF2] rounded-[48px] text-text">
                        <p id="text" className="font-bold shrink text-center 1lg:leading-tight 1md:leading-tight 1md:px-2">и покупай билеты.</p>
                    </div>
                    <div className="flex justify-center">
                        <img className="shrink w-4/5" src={tickets} alt="pay"/>
                    </div>
                </div>
            </section>

            <section id="team_section" className="flex flex-col items-center sm:pt-3 pt-36 team_section">
            <p id="headline-4" className="text-[45px] 2md:text-[35px] sm:text-[25px] font-bold pb-9">Наша команда</p>
                <div id="subSection-7" className="flex flex-row justify-center items-center gap-x-10 lg:gap-x-auto team">
                    <div className="flex grow bg-bgCard"><img className="shrink" alt="rich" src={richard1}/></div>
                    <div className="flex grow bg-bgCard"><img className="shrink" alt="anna" src={anna}/></div>
                    <div className="flex grow bg-bgCard"><img className="shrink" alt="tigran" src={tigran}/></div>
                    <div className="flex grow bg-bgCard"><img className="shrink" alt="farkhad" src={farkhad}/></div>
                </div>
            </section>       
        </main>
    );
    }
};

