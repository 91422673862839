
import React, { FC } from 'react';

interface ModalProps {
  closeModal: () => void;
}

const Modal: FC<ModalProps> = ({closeModal}) => {

  return (
       <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-5 rounded-lg">
          <button onClick={closeModal} className="float-right">Закрыть</button>
          <h2>Выберите платформу</h2>
          <a href="path_to_android_app" className="block mt-2">Загрузить для Android</a>
          <a href="path_to_ios_app" className="block mt-2">Загрузить для iOS</a>
        </div>
      </div>
  );
};

export default Modal;


