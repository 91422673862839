import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import mms from '../../assets/mms.svg';
import wrt from '../../assets/wrt.svg';

const Footer: FC = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <footer id="contact_section" className="flex flex-col pt-[8rem] md:pt-5">
      <p id='headline-5' className='text-center text-text text-[45px] 2md:text-[35px] sm:text-[25px] font-bold font-sans'>Контакты</p>
      <div id='footer_div' className="flex flex-col h-[280px] bg-[#1B1B3A] mt-[20px]">
        <p id="footer" className="text-white font-bold text-center pt-14 2md:break-words lg:text-[25px] sm:text-[20px] text-[35px]">
          Есть вопросы или предложения? <br className="hidden"/> Свяжись с нами!
        </p>
        <div id='div_mail_privacy' className="flex h-screen flex-row sm:flex-col sm:justify-center sm:gap-y-[1.5rem] mx-44 lg:mx-32 items-center justify-between">
          <div id='footer_afterMailMMS' className="flex gap-x-5 items-center text-white">
            <img id="img_mms" className="flex flex-none sm:w-10" src={mms} alt="mms" />
            <p id="mms">support@tuse.app</p>
          </div>
          <button id='footer_button' className="flex gap-x-5 text-white items-center">
            <img id="img_wrt" className="flex flex-none sm:w-10" src={wrt} alt="wrt" />
            <a href='/privacy' id="wrt" rel="noopener noreferrer" className=" text-white">
              Политика конфиденциальности
            </a>
          </button>
        </div>
      </div>

    </footer>
  );
};

export default Footer;
