import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBR9XSEtzcPa_8EI5cZRLYrGDyfn8E0ywg",
  authDomain: "tuselanding.firebaseapp.com",
  projectId: "tuselanding",
  storageBucket: "tuselanding.appspot.com",
  messagingSenderId: "915910643165",
  appId: "1:915910643165:web:44c34138f4d2613b9fba34",
  measurementId: "G-M0W8L521VP",
}

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);


export { database };
