import React, { RefObject } from "react";
import Text from './text/Text';

type MyObject = {
  id: number;
  title: string;
};

type ComponentState = {
  isMenuOpen: boolean;
};

export default class PrivacyPolicy extends React.Component<{}, ComponentState> {
  private menuButtonRef: RefObject<HTMLButtonElement>;
  arrList: MyObject[] = [];
  constructor(props: {}) {
    super(props);
    this.arrList = [
      { id: 1, title: "Термины и определения" },
      { id: 2, title: "Предмет Соглашения" },
      { id: 3, title: "Права и обязанности Компании" },
      { id: 4, title: "Права и обязанности Пользователя" },
      { id: 5, title: "Порядок оформления Заказа и получения Электронного билета" },
      { id: 6, title: "Ответственность" },
      { id: 7, title: "Персональные данные" },
      { id: 8, title: "Рекомендации" },
      { id: 9, title: "Разрешение споров и применимое право" },
      { id: 10, title: "Вступление Соглашения в силу и изменение условий Соглашения" },
    ];
    this.state = {

      isMenuOpen: false,
    };

    this.menuButtonRef = React.createRef();
  }

  handleAnchorClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const targetId = event.currentTarget.getAttribute("data-target-id");
    if (targetId) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
        this.setState({ isMenuOpen: true });
      }
    }
  };

  toggleMenu = () => {
    this.setState((prevState) => ({ isMenuOpen: !prevState.isMenuOpen }));
  };

  componentDidMount() {
    const menuButton = this.menuButtonRef.current;

    if (menuButton) {
      menuButton.addEventListener("click", this.handleMenuButtonClick);
    }
  }

  componentWillUnmount() {
    const menuButton = this.menuButtonRef.current;

    if (menuButton) {
      menuButton.removeEventListener("click", this.handleMenuButtonClick);
    }
  }

  handleMenuButtonClick = () => {
    if (this.menuButtonRef.current) {
      const button = this.menuButtonRef.current;

      if (button.classList.contains('open')) {
        button.classList.remove('open');
        button.classList.add('close');
      } else {
        button.classList.remove('close');
        button.classList.add('open');
      }
    }
  };

  render() {
    const isMenuOpen = false;

    return (
      <div id="privacy_main" className="flex flex-col w-screen bg-[#FDFEFF] pl-10 py-10 md:px-10 sm:text-[15px]">
        <p id="title_policy" className="flex justify-center text-[#1B1B3A] text-[30px] font-bold leading-tight text-center">Политика конфиденциальности</p>
        <div className={`flex flex-row-reverse justify-center gap-x-14 mt-10 md:flex-col`}>

          <nav id="nav_policy" className={`mt-[27px] w-[38%] md:w-full ${!isMenuOpen ? 'nav-visible' : 'nav-hidden'}`}>
            <div className="bg-[#E8EEF2] rounded-[24px]">
              <ul className="flex flex-col justify-center h-full gap-y-6 pt-[21px] pb-[63px]">
                {this.arrList.map((item) => (
                  <a
                    href={`#${item.title.split(' ').join('_')}`}
                    data-target-id={`headline-${item.id}`}
                    onClick={this.handleAnchorClick}
                    className="px-[35px] text-[#1B1B3A] text-[20px] font-semibold md:text-[15px]"
                    key={item.id}>
                    {item.id}. {item.title}
                  </a>
                ))}
              </ul>
            </div>
          </nav>

          <div className="flex grow mt-[27px] w-1/2 md:w-full">
            <Text />
          </div>
        </div>
      </div>
    );
  }
}
