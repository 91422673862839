import { useState, useEffect, useRef, useCallback } from "react";
import { onValue, ref } from "firebase/database";
import { database } from "./../../firebase";

const useCountdown = (initialTargetDate: string | number | null) => {
    const [countDown, setCountDown] = useState<number>(0);
    const targetDateRef = useRef<string | number | null>(initialTargetDate);

    useEffect(() => {
        const dbRef = ref(database, '/targetDate');
        const unsubscribe = onValue(dbRef, (snapshot) => {
            const targetDateFromFirebase = snapshot.val();
            if (targetDateFromFirebase) {
                targetDateRef.current = targetDateFromFirebase;
            }
        });
        return () => unsubscribe();
    }, []);

    const updateCountDown = useCallback(() => {
        if (targetDateRef.current) {
            const targetTime = typeof targetDateRef.current === 'string'
                ? new Date(targetDateRef.current).getTime()
                : targetDateRef.current;
            setCountDown(targetTime - new Date().getTime());
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(updateCountDown, 1000);
        return () => clearInterval(interval);
    }, [updateCountDown]);

    return countDown;
};

export default useCountdown;
