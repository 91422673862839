import React, { Component } from 'react';
import Main from './pages/Main';
import PrivacyPolicy from './components/privacy/PrivacyPolicy';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';

interface AppBackgroundProps { }

function AppBackground(this: any, props: AppBackgroundProps) {
  const backgroundStyle: React.CSSProperties = {
    backgroundImage: '',
  };

  return (
    <div className='flex flex-col bg-no-repeat bg-center bg-cover bg-white' style={backgroundStyle}>
      <Routes>
        <Route id='/' path='/' element={<Main />} />
        <Route path='/privacy' element={<PrivacyPolicy />} />
      </Routes>
    </div>
  );
}

export default class App extends Component {


  componentDidMount(): void {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      if (anchor.getAttribute('href')?.startsWith('#')) {
        anchor.addEventListener('click', function (e) {
          e.preventDefault();
          const ele = document.querySelector(anchor.getAttribute('href') ?? "#");
          ele?.scrollIntoView({
            behavior: 'smooth'
          });
        });
      }

    });
  }


  render() {
    return (
      <Router>
        <Header handleDownloadClick={handleDownloadClick} />
        <AppBackground />
        <Footer />
      </Router>
    );
  }
}



export const android_url = "https://play.google.com/store/apps/details?id=com.deadfonki.tuse";
export const ios_url = "https://apps.apple.com/ru/app/id1532516520/id1532516520";

export const handleDownloadClick = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  eval("_tmr.push({ type: 'reachGoal', id: 3518957, goal: 'install_click'});")

  let downloadUrl = android_url; // По умолчанию Android

  if (/android/i.test(userAgent)) {
    downloadUrl = android_url;
  } else if (/iPad|iPhone|iPod|Mac/.test(userAgent)) {
    downloadUrl = ios_url;
  }

  window.open("https://redirect.appmetrica.yandex.com/serve/460822935871983839?click_id", '_blank', 'noopener,noreferrer');
};