import React, { MouseEventHandler } from 'react';
import google_play_icon from '../../../assets/google_play_icon.svg';
import './market_compo.css';

interface AppStoreCompoProps {
    onClick: MouseEventHandler<HTMLAnchorElement>;
}

const GooglePlayCompo: React.FC<AppStoreCompoProps> = ({ onClick }) => {
    return (
        <a href='#!' className='google_play_btn' onClick={onClick}>
            <img alt='google_play' className='icon' src={google_play_icon} />
            <span className='texts'>
                <span className='text-1'>Загрузите в</span>
                <span className='text-2'>Google Play</span>
            </span>
        </a>
    );
};

export default GooglePlayCompo;
