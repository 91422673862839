import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import tuse_logo from '../../assets/tuse_logo.svg';

interface HeaderProps {
  handleDownloadClick: () => void;
}

const Header: FC<HeaderProps> = ({ handleDownloadClick }) => {
  return (
    <header className="flex px-16 header">
      <div className="flex container h-24">
        <nav id='nav_header' className="flex flex-1 justify-between items-center pl-10 sm:pl-5">
          <Link to='/'><img className="xsm:w-20" id="logo" alt="logo" src={tuse_logo}/></Link>
          <ul id="text_header" className="flex gap-8 justify-center items-center xsm:gap-5 font-medium text-[25px] 2md:text-[15px]">
            <li><a href="#about">О проекте</a></li>
            <li><a href='#team_section'>Команда</a></li>
            <li><a href="#contact_section">Контакты</a></li>
            <li><button onClick={handleDownloadClick} id="btn_load" 
                className="rounded-[48px] cursor-pointer text-white bg-btnLoad py-[10px] px-[21px] sm:py-2">
                Скачать
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
