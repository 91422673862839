import React, { MouseEventHandler } from 'react';
import app_store_icon from '../../../assets/app_store_icon.svg';
import './market_compo.css';

interface GooglePlayCompoProps {
    onClick: MouseEventHandler<HTMLAnchorElement>;
}

const AppStoreCompo: React.FC<GooglePlayCompoProps> = ({ onClick }) => {
    return (
        <a href='#!' className='app_store_btn' onClick={onClick}>
            <img alt='app_store' className='icon' src={app_store_icon} />
            <span className='texts'>
                <span className='text-1'>Загрузите в</span>
                <span className='text-2'>App Store</span>
            </span>
        </a>
    );
};

export default AppStoreCompo;
